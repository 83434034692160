<template>
    <date-range-picker-custom
        :class="['datepicker', 'datepicker--container', hasOtherDate ? 'datepicker--compare' : null, precision === 'month' ? 'datepicker--months' : null]"
        :opens="position"
        :max-date="new Date()"
        :min-date="new Date(2015, 0, 1)"
        v-model="dateRangeLocale"
        :locale-data='localeData'
        :precision="precision"
        :linked-calendars="false"
        :ranges="hasOtherDate"
        :show-dropdowns="false"
        @update="updateDate"
        @select="selectDate"
        @toggle="calendarToggle"
    >
        <div
            slot="input"
            slot-scope="input"
            :class="{'datepicker-input': true, open: isCalendarOpen}"
        >
            <div
                v-if="precision === 'month' && (input.startDate || input.endDate) && !inputTitleAfter"
                class="datepicker-input-text"
            >
                {{ input.startDate | dateFormater('MMMM YY') }}
            </div>
            <div
                v-else-if="(input.startDate || input.endDate) && !inputTitleAfter"
                class="datepicker-input-text"
            >
                {{ input.startDate | dateFormater('DD.MM.YY') }}
                <span>{{ localeData.separator }}</span>
                {{ input.endDate | dateFormater('DD.MM.YY') }}
            </div>
            <div
                v-else-if="(input.startDate || input.endDate) && inputTitleAfter"
                class="datepicker-input-text"
            >{{ inputTitleAfter }}</div>
            <div
                v-else
                class="datepicker-input-text"
            >{{ inputTitle }}
            </div>
            <img
                class="datepicker-input-icon"
                src="/img/icons/calendar.svg"
                alt=""
            />
        </div>

        <div
            slot="header"
            slot-scope="data"
            class="datepicker-header"
        >
            <span class="datepicker-header-title">{{ title }}</span>
            <div
                class="datepicker-header-icon"
                @click="data.clickCancel"
            >
                <CloseIcon />
            </div>
        </div>

        <div
            slot="ranges"
            slot-scope="data"
            style="width: 100%;"
        >
            <date-range-picker-open
                class="datepicker datepicker--without-input"
                :max-date="new Date()"
                v-model="otherDateRangeLocale"
                :locale-data='localeData'
                position="inline"
                :precision="precision"
                :ranges="false"
                :linked-calendars="false"
                :show-dropdowns="false"
                :always-show-calendars="true"
                ref="otherDateRangePicker"
                @select="selectOtherDate"
            >
                <div
                    slot="input"
                    slot-scope="data"
                    class="datepicker-header"
                >
                </div>

                <div
                    slot="footer"
                    slot-scope="data"
                    class="datepicker-footer"
                >
                    <template v-if="precision !== 'month' && hasOtherDate && windowWidth <= 992">
                        <DatePickerInputGroup
                            :separator="localeData.separator"
                            v-model="otherDateRangeLocale"
                        />
                    </template>
                </div>
            </date-range-picker-open>
        </div>

        <div
            slot="footer"
            slot-scope="data"
            class="datepicker-footer"
        >
            <template v-if="hasOtherDate && windowWidth > 992">
                <DatePickerInputGroup
                    v-if="precision !== 'month'"
                    :separator="localeData.separator"
                    v-model="otherDateRangeLocale"
                />
                <span
                    @click="!data.in_selection && data.clickApply()"
                    :class="{'btn-active': !data.in_selection, 'btn': true, 'small': true}"
                >
                    {{ data.locale.applyLabel }}
                </span>
                <DatePickerInputGroup
                        v-if="precision !== 'month'"
                    :separator="localeData.separator"
                    v-model="dateRangeLocale"
                />
            </template>
            <template v-else-if="hasOtherDate && windowWidth <= 992">
                <DatePickerInputGroup
                        v-if="precision !== 'month'"
                    :separator="localeData.separator"
                    v-model="dateRangeLocale"
                />
                <span
                    @click="!data.in_selection && data.clickApply()"
                    :class="{'btn-active': !data.in_selection, 'btn': true, 'small': true}"
                >
                    {{ data.locale.applyLabel }}
                </span>
            </template>
            <template v-else>
                <DatePickerInputGroup
                        v-if="precision !== 'month'"
                    :separator="localeData.separator"
                    v-model="dateRangeLocale"
                />
                <span
                    @click="!data.in_selection && data.clickApply()"
                    :class="{'btn-active': !data.in_selection, 'btn': true, 'small': true}"
                >
                    {{ data.locale.applyLabel }}
                </span>
            </template>
        </div>
    </date-range-picker-custom>
</template>
<script>
import DateRangePickerOpen from '@/components/DateRangePickerOpen';
import DateRangePickerCustom from '@/components/DateRangePickerCustom';
import DatePickerInputGroup from '@/components/Form/DatePickerInputGroup';
import CloseIcon from '@/components/icons/Close';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
    name: 'DatePicker',
    model: {
        prop: 'dateRange',
        event: 'update-date',
    },
    filters: {
        dateFormater: (date, format) => {
            const label = date ? moment(date).locale('ru').format(format) : '';
            return label.charAt(0).toUpperCase() + label.slice(1)
        },
    },
    props: {
        precision: {
            type: String,
            required: false,
        },
        positionBreakPoints: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        otherDate: {
            type: Object,
            default: () => ({}),
        },
        dateRange: {
            type: Object,
            required: true,
        },
        format: {
            type: String,
            required: false,
            default: 'Y-MM-DD',
        },
        title: {
            type: String,
            required: false,
            default: 'Выберите периоды',
        },
        inputTitle: {
            type: String,
            required: false,
            default: 'Выбор периода',
        },
        inputTitleAfter: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            isCalendarOpen: false,
            dateRangeLocale: { ...this.dateRange },
            dateRangeOld: { ...this.dateRange },
            otherDateRangeLocale: { ...this.otherDate },
            localeData: {
                direction: 'rtl',
                format: this.format,
                separator: '-',
                applyLabel: 'Применить',
                daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                firstDay: 1,
            },
        };
    },
    computed: {
        ...mapState(['windowWidth']),
        ...mapGetters(['isMobile']),
        ...mapGetters({
            'dateStore': 'date',
            'compareDateStore': 'compareDate',
        }),
        position() {
            let position = 'center';
            let poits = Object.keys(this.positionBreakPoints);

            if (poits.length) {
                for (let i = 0; i < poits.length; i++) {
                    if (this.windowWidth <= +poits[i]) {
                        position = this.positionBreakPoints[poits[i]];
                        break;
                    }
                }
            }
            return position;
        },
        hasOtherDate() {
            return !!Object.keys(this.otherDate).length;
        },
    },
    watch: {
        dateRange: {
            handler(val) {
                this.dateRangeLocale = { ...val };
                this.dateRangeOld = { ...val };
            },
            deep: true,
        },
        otherDate: {
            handler(val) {
                this.otherDateRangeLocale = { ...val };
            },
            deep: true,
        },
    },
    methods: {
        calendarToggle() {
            this.dateRangeLocale = { ...this.dateRangeOld };
            this.isCalendarOpen = !this.isCalendarOpen;
        },
        selectDate(date) {
            this.dateRangeLocale.startDate = moment(date.startDate).format(this.format);
            this.dateRangeLocale.endDate = moment(date.endDate).format(this.format);
        },
        selectOtherDate(date) {
            this.otherDateRangeLocale.startDate = moment(date.startDate).format(this.format);
            this.otherDateRangeLocale.endDate = moment(date.endDate).format(this.format);
        },
        updateDate(date) {
            if (this.dateRangeLocale.startDate && this.dateRangeLocale.endDate) {
                this.dateRangeLocale = {
                    startDate: moment(this.dateRangeLocale.startDate).format(this.format),
                    endDate: moment(this.dateRangeLocale.endDate).format(this.format),
                };
                this.dateRangeOld = { ...this.dateRangeLocale };
                this.$emit('update-date', this.dateRangeLocale);
            }
            if (this.otherDateRangeLocale.startDate && this.otherDateRangeLocale.endDate) {
                this.otherDateRangeLocale = {
                    startDate: this.otherDateRangeLocale.startDate && moment(this.otherDateRangeLocale.startDate).format(this.format),
                    endDate: this.otherDateRangeLocale.endDate && moment(this.otherDateRangeLocale.endDate).format(this.format),
                };
                this.$emit('update-other-date', this.otherDateRangeLocale);
            }
        },
    },
    components: {
        DateRangePickerCustom,
        DateRangePickerOpen,
        DatePickerInputGroup,
        CloseIcon,
    },
    mounted() {
        if (this.dateRange.startDate && this.dateRange.endDate) {
            this.dateRangeLocale = {...this.dateRange};
            this.dateRangeOld = {...this.dateRange};
        }
    },
};
</script>

<style lang="scss">
@import '@/styles/vars.scss';

.datepicker {
    width: 100%;


    &.datepicker--months:not(.datepicker--compare) .show-calendar.dropdown-menu {
        min-width: unset !important;
    }
    &.datepicker--without-input {
        .reportrange-text {
            display: none;
        }
        .show-calendar.dropdown-menu {
            margin-top: 0;
            .calendars {
                padding-bottom: 0;
                margin-top: 0;
            }
        }
        .datepicker-header {
            padding: 0;
        }

        .datepicker-footer {
            padding: 0;
        }

        .daterangepicker {
            min-width: unset !important;
            position: relative;
            box-shadow: none !important;
            border: none;
        }
        .show-calendar.dropdown-menu .calendars {
            padding: {
                left: 0;
                right: 0;
            }
        }

        .show-calendar.dropdown-menu .col {
            &.left {
                border-left: none;
            }
        }
    }

    .show-calendar.dropdown-menu {
        border-radius: 0;
        border: none;
        box-shadow: $shadow;
        min-width: 458px;
        width: 100%;
        box-sizing: border-box;
        top: 100%;
        margin-top: 4px;

        &.opensleft {
            right: 0;
        }

        &.opensright {
            left: 0;
        }

        &::before,
        &::after {
            display: none;
        }

        .calendars {
            margin-top: 10px;
            padding: 0 0 15px;

            &-container {
                width: 100%;
                position: relative;
                justify-content: space-between;
                margin-bottom: 15px;

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    bottom: -15px;
                    left: 0;
                    background-color: $c-grey5;
                }

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    right: 50%;
                    top: 0;
                    width: 1px;
                    height: 100%;
                    background-color: $c-grey5;
                    transform: translateX(50%);
                }
            }
        }

        .calendar-table {
            border: none;
        }

        .col.left {
            .calendar-table {
                padding-right: 0;
            }
        }

        .col.left,
        .col.right {
            width: 100%;
            min-width: unset;
            padding: 0;
        }
    }

    .calendar-table {
        th,
        td {
            box-sizing: border-box;
            font-size: 13px;
            border-radius: 0;
            padding: 0;
            border: 0;
            min-width: 27px;
            height: 21px;
            width: 100%;
            line-height: (21/13);
        }

        th {
            cursor: auto;
            font-weight: 400;
        }

        tbody tr:first-child {
            th {
                padding-bottom: 6px;
            }

            opacity: 0.5;
        }

        td {
            color: $c-dark2;
            font-weight: 500;

            &:hover {
                color: $c-white;
                border-radius: 0;
                background-color: $c-primary2;
            }
        }

        .end-date.start-date {
            border-radius: 0;
        }

        .off.disabled,
        .off {
            pointer-events: none;
            color: transparent;
            opacity: 0;
            width: 0;
            height: 0;
            min-width: 0;
            line-height: 0;
        }

        .today,
        .today.weekend {
            position: relative;
            background: $c-white;
            color: $c-primary;

            &::after {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                display: block;
                width: calc(100% - 2px);
                height: calc(100% - 2px);
                border: 1px solid $c-primary;
                background-color: transparent;
            }

            &.today:hover {
                background: $c-grey2;
            }

            &:hover {
                &::after {
                    display: none;
                }

                background: $c-primary2;
                color: $c-white;
            }

            &.in-range {
                &::after {
                    display: none;
                }

                background-color: $c-grey;
                color: $c-white;
            }
        }

        .in-range,
        .in-range.weekend,
        .in-range.disabled {
            color: $c-white;
            border-radius: 0;
            background-color: $c-primary;
        }

        .disabled {
            opacity: 0.4;
            text-decoration: auto;
            background-color: $c-grey4;
        }

        .weekend {
            font-weight: 600;
            color: #000;
        }

        .prev,
        .next {
            display: none;
        }

        .month {
            padding-bottom: 15px;
            line-height: (15/13);
            font-weight: 500;
            text-align: left;
            &--clickable {
                cursor: pointer;
                &:hover {
                    background-color: rgba(0, 0, 0, .15)
                }
            }
        }
    }

    .datepicker-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 28px 18px 26px;

        &-title {
            color: $c-grey;
            font-size: 14px;
            line-height: (16/14);
        }

        &-icon {
            cursor: pointer;
            line-height: 0;
        }
    }

    .reportrange-text {
        border: none;
        padding: 0;
    }

    .datepicker-input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 1;
        box-sizing: border-box;
        border: 1px solid $c-grey4;
        border-radius: 4px;
        padding: 11px 1.1rem 12px 1.6rem;

        &-text {
            display: inline-block;
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
            color: $c-dark;
            //display: flex;
            font-size: 13px;
            line-height: (16/13);

            span {
                margin: 0 10px;
                line-height: 1;
            }
        }

        &:hover {
            border-color: $c-dark2;
        }

        &.open {
            background-color: $c-grey5;
            border-color: $c-dark2;

            .datepicker-input-text {
                color: $c-dark2;
            }
        }
    }

    .datepicker-footer {
        font-size: 10px;
        padding: 0 24px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.datepicker--container {
    .daterangepicker {
        width: auto !important;
    }
    .show-calendar.dropdown-menu .col {
        &.left {
            border-left-width: 2px;
            border-left-color: #1c1c1c;
            padding-left: 24px;
            padding-right: 17px;
        }
        &.right {
            padding-right: 22px;
            padding-left: 17px;
        }
    }
}
.datepicker .show-calendar.dropdown-menu .calendars-container::before {
    display: none;
}
.datepicker .calendar-table th, .datepicker .calendar-table td {
    width: auto;
}
.datepicker .calendar-table td {
    padding: 8px 0;
}
.datepicker .calendar-table .prev, .datepicker .calendar-table .next {
    display: table-cell;
    cursor: pointer;
    outline: none;
    &:hover {
        background-color: rgba(0, 0, 0, .15) !important
    }
}
.datepicker .calendar-table .month {
    padding: 8px 0;
    text-align: center;
}
.datepicker .show-calendar.dropdown-menu .col {
    &.left, .right {
        width: 100%;
    }
}
.datepicker .daterangepicker td.active {
    &, &:hover {
        color: #fff;
        border-radius: 0;
        background-color: #fa1522;
    }
}
.datepicker .daterangepicker .drp-calendar {
    max-width: unset;
}

.datepicker {
    .show-calendar.dropdown-menu {
        .calendars {
            lign-items: center;
            flex-wrap: unset;
        }
    }
}

.datepicker-footer {
    .btn {
        background-color: #e30613;
        line-height: 1.6em;
    }
    .btn:hover {
        background-color: #fa1522;
    }
}
@media (max-width: 576px) {
    .datepicker-footer {
        .btn {
            line-height: 2.4rem;
        }
    }
}

@media (max-width: 992px) {
    .datepicker {
        .show-calendar.dropdown-menu {
            .calendars {
                flex-wrap: wrap;
            }
        }
        .datepicker--container {
            .show-calendar.dropdown-menu {
                .col.left {
                    border-left-color:unset;
                    border-left-width:0px;
                }
            }
        }
        &.datepicker--compare {
            .calendars {
                flex-wrap: wrap;
                & > *:first-child {
                    width: 100%;
                    margin-bottom: 15px;
                    .calendars {
                        margin-bottom: 15px;
                    }
                }
            }
            & > .dropdown-menu {
                width: 100% !important;
                max-width: 400px;
            }
            .datepicker-footer {
                flex-wrap: wrap;
                justify-content: center;
                & > * {
                    width: 100%;
                    margin-bottom: 12px;
                }
                .datepicker-footer-input-group {
                    justify-content: center;
                }
                .btn {
                    margin-bottom: 0;
                    order: 1;
                    max-width: 186px;
                }
            }
        }
    }
}

@media (min-width: 2320px) {
    .datepicker {
        .datepicker-input-text {
            font-size: 2rem;
            line-height: (25 / 20);
        }
        .datepicker-input {
            border-radius: 6px;
            padding: 18px 17px 19px 25px;
        }
        .datepicker-input-icon {
            width: 38px;
            height: 38px;
        }
        .show-calendar.dropdown-menu {
            min-width: 620px;
            margin-top: 8px;
            .col {
                &.left {
                    padding-left: 32px;
                    padding-right: 32px;
                }
                &.right {
                    padding-right: 32px;
                    padding-left: 32px;
                }
            }
            .calendars {
                padding-bottom: 20px;
            }
            .calendars-container {
                margin-bottom: 22px;
                &::after {
                    bottom: -22px;
                }
            }
        }
        .datepicker-header {
            padding: 24px 32px 24px 32px;
        }
        .datepicker-footer {
            padding: 0 32px 30px;
            .btn {
                font-size: 2rem;
                height: 40px;
                padding: 0 50px;
            }
            .datepicker-footer-input-group span {
                margin: 0 10px;
                font-size: 2rem;
            }
            .datepicker-footer-input {
                max-width: 114px;
                font-size: 1.8rem;
                padding: 8px 10px;
            }
        }
        .datepicker-header-title {
            font-size: 2rem;
        }
        .datepicker-header-icon svg {
            height: 24px;
            width: 24px;
        }

        .calendar-table th,
        .calendar-table td {
            font-size: 1.8rem;
            min-width: 37px;
            height: 30px;
        }
    }
    .daterangepicker {
        th.month {
            font-size: 1.9rem;
        }
    }
}

@media (max-width: 1200px) {
    .datepicker--container {
        .show-calendar.dropdown-menu .col {
            &.left {
                padding-left: 12px;
                padding-right: 10px;
            }
            &.right {
                padding-right: 12px;
                padding-left: 10px;
            }
        }
    }
}
@media (max-width: 540px) {
    .datepicker {
        &.datepicker--compare .calendars > .dropdown-menu {
            max-width: 300px;
        }
    }
    .datepicker--container {
        .show-calendar.dropdown-menu .col {
            &.left {
                padding: {
                    left: 0;
                    right: 0;
                }
            }
            &.right {
                padding: {
                    left: 0;
                    right: 0;
                }
            }
        }
    }
}

@media (max-width: 1010px) {
    .datepicker .datepicker-input {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media (max-width: 620px) {
    .datepicker .datepicker-input {
        padding-top: 6px;
        padding-bottom: 6px;
    }
    .datepicker {
        .show-calendar.dropdown-menu {
            width: unset;
        }
    }
}

@media (max-width: 576px) {
    .datepicker {
        .datepicker-header,
        .show-calendar.dropdown-menu .calendars,
        .datepicker-footer {
            padding-left: calc(5 / 320 * 100%);
            padding-right: calc(5 / 320 * 100%);
        }

        .datepicker-header {
            padding-top: 15px;
            padding-bottom: 12px;

            &-title {
                font-size: 1.3rem;
            }
        }

        .show-calendar.dropdown-menu .calendars {
            padding-bottom: 12px;
        }

        .datepicker-footer {
            padding-bottom: 15px;

            .btn {
                font-size: 1.3rem;
            }
        }

        .datepicker-footer-input-group {
            width: 214px;
            margin-bottom: 12px;
        }

        .calendar-table {
            td,
            th:not(.month) {
                font-size: 1.1rem;
                min-width: 20px;
            }
        }

        .show-calendar.dropdown-menu {
            min-width: 316px;
            width: 100%;
            max-width: 360px;
        }

        .datepicker-footer {
            flex-wrap: wrap;
            justify-content: center;
            padding-bottom: 15px;
            // .datepicker-footer-input-group {
            // 	width: 100%;
            // }
        }
    }
}
</style>
