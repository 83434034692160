<template>
    <BarChartHorizontal
        :styles="styles"
        :chart-data="chartData"
        :chart-options="chartOptions"
    />
</template>

<script>
import BarChartHorizontal from '../Charts/BarChartHorizontal';

export default {
    name: "BarChart",
    components: {
        BarChartHorizontal,
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        }
    },
    data() {
        return {
            chartData: {},
            chartOptions: {
                fontPatch: 2,
                cornerRadius: 3,
                scales: {
                    yAxes: [
                        {
                            barThickness: 11,
                            maxBarThickness: 11,
                            ticks: { fontSize: 9, padding: 10 },
                        },
                    ],
                },
            },
            styles: {
                height: 0,
            },
            innerPadding: 22,
        }
    },
    mounted() {
        this.fillData();
    },
    methods: {
        fillData() {
            const labels = window.fixWordWrap(this.data.labels, 18);
            const data = this.data.data;
            let data2 = this.data.data2;
            if (!data) return;
            this.styles.height = (data.length + 1) * this.chartOptions.scales.yAxes[0].maxBarThickness + data.length * this.innerPadding + 'px';
            const datasets = [
                {
                    defaultFontColor: '#555555',
                    backgroundColor: '#E30613',
                    borderWidth: 0,
                    data,
                },
            ];
            if (data2 && data2.length) {
                if (data2.length > data.length) {
                    data2 = data2.slice(0, data.length)
                }
                datasets.push({
                    defaultFontColor: '#555555',
                    backgroundColor: '#878787',
                    borderWidth: 0,
                    data: data2,
                });
            }
            // console.log('datasets:', datasets);
            this.chartData = {
                labels,
                datasets,
            };
        },
    },
    watch: {
        data: {
            handler() {
                this.fillData();
            },
            deep: true,
        }
    }
}
</script>

<style scoped>

</style>
