<template>
    <div class="h-scroll" :class="classes">
        <table
            v-if="($router.currentRoute.path === '/' && !isMobile) || (windowWidth > 1200 && $router.currentRoute.path !== '/')"
            class="table"
        >
            <thead>
                <tr>
                    <th
                        v-for="(header, i) in Object.keys(headers)"
                        :class="{orderable: !!headers[header].ordering}"
                        @click="setOrderData(header)"
                        :key="i"
                    >
                        {{ (windowWidth < 1440 && header === 'goTo') ?  headers[header].title.split(' ')[0] : headers[header].title }}
                        <img
                            v-if="headers[header].ordering && orderBy === header"
                            :style="{transform: orderDir === -1 ? 'rotate(180deg)' : null}"
                            src="/img/icons/triangleUp.svg"
                            alt="^"
                        >
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(post, j) in posts"
                    @click="$emit('click', post)"
                    :class="{clickable}"
                    :key="j"
                >
                    <template v-for="(header, i) in Object.keys(headers)">
                        <td
                            :key="i"
                            v-if="headers[header].format_number && (typeof post[header] !== 'object')"
                            :class="[headers[header].class, postsCompareLocal.length ? 'td--compare' : null]"
                        >
                            {{ (!postsCompareLocal[j] ? post[header] : null) | format_number(true) }}

                            <span v-if="postsCompareLocal[j] && postsCompareLocal[j].id === post.id">
                                {{post[header] | format_number(true)}}
                            </span>
                            <span
                                v-if="postsCompareLocal[j] && postsCompareLocal[j].id === post.id"
                                :class="{
                                    'up': getPercent(postsCompareLocal[j][header], post[header]) > 0,
                                    'down': getPercent(postsCompareLocal[j][header], post[header]) < 0
                                }"
                            >
                                {{ getPercent(postsCompareLocal[j][header], post[header], true) }}
                            </span>
                            <span v-if="postsCompareLocal[j] && postsCompareLocal[j].id === post.id">
                                {{postsCompareLocal[j][header] | format_number(true)}}
                            </span>
                        </td>
                        <td
                            :key="i"
                            v-else-if="typeof post[header] === 'object'"
                            :class="[headers[header].class]"
                        >
                            <div
                                v-if="headers[header].type === 'dateTimeStatus'"
                                class="td--date-time"
                            >
                                <span
                                    class="td--inner-date"
                                    v-if="post[header].date"
                                >
                                    {{post[header].date}}
                                </span>
                                <span
                                    class="td--inner-time"
                                    v-if="post[header].time"
                                >
                                    {{post[header].time}}
                                </span>
                                <span
                                    :class="{'td--inner-status': true, 'processed': post[header].status === 'Обработан', 'new': post[header].status === 'Новый'}"
                                    v-if="post[header].status"
                                >
                                    {{post[header].status}}
                                </span>
                            </div>

                            <div
                                v-if="headers[header].type === 'nameBranch'"
                                class="td--region-branch"
                            >
                                <span class="td--inner-region-name">
                                    {{post[header].name}}
                                </span>
                                <span class="td--inner-region-branch">
                                    {{post[header].branch}}
                                </span>
                            </div>
                        </td>
                        <td
                            :key="i"
                            v-else
                            :class="[headers[header].class]"
                            @click.self="btnCellClick"
                        >
                            {{headers[header].class !== 'td--btn' ? post[header] : null }}
                            <a
                                v-if="headers[header].class === 'td--btn'"
                                @click.stop
                                :href="post[header]"
                                target="_blank">
                                <span>Перейти</span>
                                <img
                                    src="/img/icons/arrow-right.svg"
                                    alt=">"
                                >
                            </a>
                        </td>
                    </template>
                </tr>
            </tbody>
        </table>
        <table
            v-else
            class="table table-mobile"
        >
            <template v-for="(post, j) in posts">
                <tbody :key="j">
                    <tr>
                        <td
                            class="th"
                            colspan="2"
                        >{{ j + 1}}
                        </td>
                    </tr>
                    <tr
                        v-for="(header, i ) in Object.keys(headers)"
                        :key="i"
                    >
                        <td :class="{orderable: headers[header].ordering}" class="td--th">
                            {{headers[header].title}}
                            <img
                                v-if="headers[header].ordering && orderBy === header"
                                :style="{transform: orderDir === -1 ? 'rotate(180deg)' : null}"
                                src="/img/icons/triangleUp.svg"
                                alt="^"
                            >
                            <!--{{console.log(postsCompareLocal, post)}}-->
                        </td>
                        <td
                            v-if="headers[header].format_number && (typeof post[header] !== 'object')"
                            :class="[headers[header].class, postsCompareLocal.length ? 'td--compare' : null]"
                        >
                            {{ (!postsCompareLocal[j] ? post[header] : null) | format_number(true) }}

                            <span v-if="postsCompareLocal[j] && postsCompareLocal[j].id === post.id">
                                {{post[header] | format_number(true)}}
                            </span>
                            <span
                                v-if="postsCompareLocal[j] && postsCompareLocal[j].id === post.id"
                                :class="{
                                    'up': getPercent(postsCompareLocal[j][header], post[header]) > 0,
                                    'down': getPercent(postsCompareLocal[j][header], post[header]) < 0
                                }"
                            >
                                {{ getPercent(postsCompareLocal[j][header], post[header], true) }}
                            </span>
                            <span v-if="postsCompareLocal[j] && postsCompareLocal[j].id === post.id">
                                {{postsCompareLocal[j][header] | format_number(true)}}
                            </span>
                        </td>
                        <td
                            :key="i"
                            v-else-if="typeof post[header] === 'object'"
                            :class="[headers[header].class]"
                        >
                            <!--{{ console.log(header, post[header])}}-->
                            <div
                                v-if="post[header] && post[header].date && post[header].time && post[header].status"
                                class="td--date-time"
                            >
                                <span
                                    class="td--inner-date"
                                    v-if="post[header].date"
                                >
                                    {{post[header].date}}
                                </span>
                                <span
                                    class="td--inner-time"
                                    v-if="post[header].time"
                                >
                                    {{post[header].time}}
                                </span>
                                <span
                                    :class="{'td--inner-status': true, 'processed': post[header].status === 'Обработан', 'new': post[header].status === 'Новый'}"
                                    v-if="post[header].status"
                                >
                                    {{post[header].status}}
                                </span>
                            </div>

                            <div
                                v-if="post[header] && post[header].name && post[header].branch"
                                class="td--region-branch"
                            >
                                <span class="td--inner-region-name">
                                    {{post[header].name}}
                                </span>
                                <span class="td--inner-region-branch">
                                    {{post[header].branch}}
                                </span>
                            </div>
                        </td>

                        <td
                            v-else
                            :class="[headers[header].class, 'asfi']"
                            @click.self="btnCellClick"
                        >
                            {{headers[header].class !== 'td--btn' ? post[header] : null }}
                            <a
                                @click.stop
                                :href="post[header]"
                                target="_blank"
                                v-if="headers[header].class === 'td--btn'"
                            >
                                <span>Перейти</span>
                                <img
                                    src="/img/icons/arrow-right.svg"
                                    alt=">"
                                >
                            </a>
                        </td>
                    </tr>
                </tbody>
            </template>
        </table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Posts',
    data() {
        return {
            orderBy: null,
            orderDir: -1,
        }
    },
    model: {
        event: 'change',
        prop: 'orderData',
    },
    props: {
        classes: {
            type: Array,
            default: () => []
        },
        posts: {
            type: Array,
            required: true,
        },
        postsCompare: {
            type: Array,
            required: false,
            default: () => [],
        },
        headers: {
            type: Object,
            required: true,
        },
        ordering: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        ...mapGetters(['isMobile', 'windowWidth', 'compareDate']),
        console() {
            return window.console;
        },
        clickable(){
            return this.$listeners && this.$listeners.click;
        },
        orderDataLocal() {
            return {
                orderBy: this.orderBy,
                orderDir: this.orderDir,
            }
        },
        postsCompareLocal() {
            return this.compareDate.startDate ? this.postsCompare : [];
        }
    },
    mounted() {
        // console.log(this.posts);
        // console.log(this.postsCompareLocal);
    },
    methods: {
        getPercent(val1, val2, hasPercent = false) {
            let percent = Math.round((val1 / val2) * 100) - 100;
            if (isNaN(percent)) {
                percent = 0;
            }
            if (hasPercent) {
                return percent > 0 ? '+' + percent + ' %' : percent + ' %';
            } else {
                return percent;
            }
        },
        btnCellClick(e) {
            if (e.target.querySelector('a')){
                e.target.querySelector('a').click();
            }
        },
        setOrderData(orderBy) {
            if (!this.headers[orderBy].ordering) return;
            if (this.orderBy === orderBy) {
                this.orderDir = -this.orderDir;
            } else {
                this.orderBy = orderBy;
                this.orderDir = 1;
            }
        }
    },
    watch: {
        orderDataLocal(orderDataLocal) {
            this.$emit('change', orderDataLocal);
        },
        orderData(orderData, oldOrderData) {
            if (orderData.orderBy === oldOrderData.orderBy && orderData.orderDir === oldOrderData.orderDir) return;
            this.orderBy = orderData.orderBy || this.orderBy;
            this.orderDir = orderData.orderDir || this.orderDir;
        },
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';

.card {
    .table {
        margin-top: 20px;
        tbody {
            tr {
                height: 70px;
            }
            td {
                padding: 18px 8px 10px;
            }
        }
    }
    &.table-wrap .table.table-mobile {
        margin: 0;
    }
}


.table {
    /*border-collapse: collapse;*/
    width: 100%;
    text-align: center;
    border-spacing: 0;

    thead {
        background-color: rgba(#000000, 0.05);
        border-radius: 4px;

        tr {
            height: 50px;
        }

        th {
            white-space: nowrap;
            font-size: 13px;
            font-weight: 500;
            height: 50px;
            padding: 0 8px;
            cursor: default;

            &.orderable {
                cursor: pointer;
                transition: color .3s ease;
                &:hover {
                    color: $c-primary;
                }
            }
            img {
                margin-left: 3px;
                vertical-align: middle;
            }
        }
    }

    tbody {
        tr {
            td {
                border: 1px solid transparent;
                border-bottom-color: #e0e0e0;
            }
            &:last-child {
                td {
                    border-bottom-color: transparent;
                }
            }
            &.clickable {
                cursor: pointer;
                td {
                     transition: border .3s ease;
                }
                &:hover {
                    td {
                        border-top-color: $c-primary;
                        border-bottom-color: $c-primary;
                        &:first-child {
                            border-left-color: $c-primary;
                        }
                        &:last-child {
                            border-right-color: $c-primary;
                        }
                    }
                }
            }
        }
        td {
            font-size: 15px;
            color: #878787;
            padding: 29px 8px;
            line-height: (17/15);
            a {
                text-decoration: none;
                span, img {
                    display: inline-block;
                    vertical-align: middle;
                }
                span {
                    margin-right: 4px;
                }
                &, &:focus {
                    color: #1C1C1C
                }
                &:hover{
                    color: #000;
                    text-decoration: underline;
                }
            }

            & > span {
                display: block;
                font-size: 1.4rem;
                font-weight: 700;
                line-height: 1;
                margin-bottom: 4px;
                &:last-child {
                    margin-bottom: 0;
                }
                &.up {
                    color: $c-green;
                }
                &.down {
                    color: $c-primary;
                }
            }
            .td--date-time,
            .td--region-branch {
                & > span {
                    display: block;
                }
                // display: flex;
                // flex-direction: column;
            }
            .td--date-time {
                min-width: 130px;
            }
            .td--inner-date {
                margin-bottom: 15px;
            }
            .td--inner-time {
                margin-bottom: 14px;
            }
            .td--inner-status {
                text-align: center;
                margin: 0 auto;
                max-width: 94px;
                border: 1px solid #878787;
                border-radius: 4px;
                //padding: 3px calc(8 / 215 * 100%) 4px;
                padding: 3px 0 4px;
                &.processed {
                    color: #5aa454;
                    border-color: #5aa454;
                }
                &.new {
                    color: #e30613;
                    border-color: #e30613;
                }
            }
            .td--inner-region-name {
                margin-bottom: 15px;
            }

            &.td--date {
                font-size: 13px;
                color: #878787;
            }

            &.td--text {
                padding: 10px 8px;
                font-size: 14px;
                text-align: left;
                color: #1c1c1c;
                max-width: 173px;
                word-break: break-word;
            }

            &.td--center {
                text-align: center;
            }
            &.td--compare {
                padding: 10px 8px;
            }

            &.td--btn {
                cursor: pointer;
                font-size: 12px;
                padding: 0;
                a {
                    display: block;
                    text-decoration: none;
                    transition: color .3s ease;
                }
                button {
                    outline: none;
                    padding: 0;
                    border: none;
                    background-color: transparent;
                    font-size: 1.2rem;
                    line-height: (15/12);
                    img {
                        margin-left: 6px;
                        vertical-align: middle;
                    }
                }
                &:hover {
                    a {
                        color: $c-primary;
                    }
                }
            }
        }
    }
}
.card.table-wrap .table-mobile.table tbody tr {
    height: unset;
}
.card .table-mobile.table,
.table-mobile.table {
    tbody {
        tr:not(tr:first-child) td:first-child {
            border-right: 1px solid #e0e0e0;
        }

        tr:first-child {
            border-bottom: none;
        }

        tr {
            td {
                max-width: unset;
                text-align: left;
                font-size: 1.5rem;
                font-weight: 400;
                padding: 7px 12px 7px 12px;
                vertical-align: baseline;
                .td--inner-status {
                    font-size: 1.2rem;
                    margin: 0;
                }
                &.td--th {
                    position: relative;
                    img {
                        margin-left: 3px;
                        vertical-align: middle;
                        position: absolute;
                        top: 17px;
                        right: 4px;
                    }
                }
                &:first-child {
                    line-height: (20/14);
                    font-weight: 500;
                    color: #1c1c1c;
                    font-size: 1.4rem;
                    padding: 7px 12px 7px 12px;
                }

                &:last-child {
                    width: calc(199 / 340 * 100%);
                    padding-left: 15px;
                }

                &.td--text {
                    line-height: (17/14);
                    font-size: 1.4rem;
                }
                &.td--date {
                    padding: 7px 12px 7px 12px;
                    line-height: (16/13);
                    font-size: 1.3rem;
                }
                &.td--btn {
                    cursor: pointer;
                    padding: 7px 12px 7px 12px;
                    font-size: 1.4rem;
                    button {
                        outline: none;
                        padding: 0;
                        border: none;
                        background-color: transparent;
                        font-size: 1.2rem;
                        line-height: (15/12);

                        img {
                            margin-left: 6px;
                            vertical-align: middle;
                        }
                    }
                }
            }
            &:last-child td {
                padding-bottom: 10px;
            }
            &:nth-child(2) td {
                padding-top: 10px;
            }
            &:first-child {
                background: rgba(0, 0, 0, 0.05);
                border-radius: 4px 4px 0px 0px;

                td {
                    line-height: (20/14);
                    text-align: center;
                    font-weight: 500;
                    font-size: 1.4rem;
                    padding: 3px 0 2px;
                }
            }
        }
    }
}

@media (min-width: 2320px) {
    .card.table-wrap .table {
        margin-top: 32px;
    }
    .table {
        thead {
            border-radius: 6px;

            tr {
                height: 80px;
            }

            th {
                font-size: 20px;
                height: 80px;
                padding: 0 8px;
                img {
                    margin-left: 6px;
                    width: 10px;
                }
            }
        }

        tbody {
            td {
                font-size: 24px;
                padding: 35px 15px;
                //&.compare
                & > span {
                    font-size: 2.4rem;
                    margin-bottom: 8px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                &.td--compare,
                &.td--text {
                    padding: 16px 14px;
                }
                &.td--date {
                    font-size: 20px;
                }
                .td--date-time {
                    min-width: 200px;
                }
                &.td--btn {
                    button {
                        font-size: 2.2rem;
                        img {
                            margin-left: 10px;
                            width: 10px;
                        }
                    }
                }
                .td--inner-status {
                    max-width: 145px;
                }
                &.td--text {
                    line-height: (27 / 22);
                    font-size: 22px;
                    max-width: 265px;
                }
            }
        }
    }
}

@media (max-width: 1500px) {
    .card.table-wrap {
        .table {
            thead {
                th {
                    font-size: 12px;
                    padding: 0 6px;
                }
            }

            tbody {
                td {
                    font-size: 14px;
                    padding: 14px 6px 6px;

                    &.td--date {
                        font-size: 12px;
                    }

                    &.td--text {
                        font-size: 12px;
                        max-width: 130px;
                    }
                }
                tr {
                    height: unset;
                }
            }
        }
    }
}

@media (max-width: 1300px) {
    .card.table-wrap .table {
        margin-top: 10px;
        thead th {
            padding: 0 4px;
        }
        tbody {
            td {
                padding: 10px 4px 6px;
            }
            tr {
                height: 70px;
            }
        }
    }
}

@media (max-width: 768px) {
    .card .h-scroll {
        padding-top: 10px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
    }
}
</style>
