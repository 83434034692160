<template>
    <svg
         :style="styles"
         @click="$emit('click')"
         width="17"
         height="18"
         viewBox="0 0 17 18"
         fill="none"
         @mouseenter="color = '#E30613'"
         @mouseleave="color = '#1C1C1C'"
         xmlns="http://www.w3.org/2000/svg">
        <path
              d="M14.9083 16.7938L0.810356 2.69588C0.456625 2.34215 0.456625 1.76798 0.810356 1.41425C1.16409 1.06052 1.73826 1.06052 2.09199 1.41425L16.1899 15.5122C16.5437 15.8659 16.5437 16.4401 16.1899 16.7938C15.8362 17.1476 15.262 17.1476 14.9083 16.7938Z"
              :fill="color"
        />
        <path
              d="M16.1898 2.69619L2.09188 16.7941C1.73815 17.1479 1.16398 17.1479 0.810249 16.7941C0.456518 16.4404 0.456518 15.8662 0.810249 15.5125L14.9082 1.41456C15.2619 1.06083 15.8361 1.06083 16.1898 1.41456C16.5436 1.76829 16.5436 2.34246 16.1898 2.69619Z"
              :fill="color"
        />
    </svg>

</template>

<script>
export default {
    props: {
        styles: {
            type: Object,
            default: () => ({}),
        }
    },
    data() {
        return {
            color: '#1C1C1C',
        }
    },
}
</script>

<style scoped>
    svg {
        cursor: pointer
    }
</style>
