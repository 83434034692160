<template>
    <div class="footer">
        <div class="container">
            <div class="footer-inner">
                <div v-if="$skin === 'magnit'" class="copyright">АО "Тандер" © 2022</div>
                <div v-else class="copyright">Все права защищены © 2022</div>
                <a
                    :href="`mailto:${$email}`"
                    class="send-message"
                >
                    <img
                        v-if="$skin === 'magnit'"
                        src="/img/icons/message-dark.svg"
                        alt="msg"
                    >
                    <img
                        v-else
                        src="/img/icons/message.svg"
                        alt="msg"
                    >
                    <span>Написать в поддержку</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Footer',
    }
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';

.footer {
    .copyright,
    .send-message {
        color: $c-white;
        font-size: 1.4rem;
        line-height: (18/14);
    }

    .send-message {
        cursor: pointer;
        text-decoration: underline;
        display: flex;
        align-items: center;

        img {
            margin-right: 10px;
        }
    }
}

.skin-magnit {
    .footer {
        .copyright,
        .send-message {
            color: #1f2020;
        }
    }
}

@media (min-width: 2320px) {
    .footer {
        &-inner {
            height: 210px;
        }
        .copyright,
        .send-message {
            font-size: 2.2rem;
            line-height: (18/14);
        }
        .send-message img {
            width: 42.5px;
            margin-right: 28px;
        }
    }
}

@media (max-width: 1200px) {
    .footer {
        .copyright,
        .send-message {
            font-size: 1.3rem;
        }
    }
}

@media (max-width: 992px) {
    .footer {
        .copyright,
        .send-message {
            font-size: 1.2rem;
        }
    }
}

@media (max-width: 768px) {
    .footer {
        .copyright,
        .send-message {
            font-size: 1rem;
        }

        .copyright {
            margin-right: 20px;
        }

        .send-message {
            min-width: 145px;
        }
    }
}
</style>
